
import { Vue, Component } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { twoDecimal } from '@/utils/validate'
import { ProjectArea, ProjectUser } from '@/types/project'

@Component
export default class Area extends Vue {
  // 是否配置全部区域的位置
  private isAllAreaSetRange = false
  private loading = false
  private showAddProjectArea = false
  private projectAreaList: ProjectArea[] = []
  private projectAreaTitle = '新增区域'
  private addProjectAreaInfo: any = {
    areaName: '',
    areaAcreage: '',
    liableUser: '',
    conserveLevel: '', // 养护等级
    conserveDesc: '', // 养护标准描述
    conserveElementIds: [], // 养护要素IDS
    conservePointIds: [] // 养护点位IDS
  }

  xxxx = []

  elementList = []
  facilityList = []

  private userList: ProjectUser[] = []

  private addProjectAreaRules = {
    areaName: [{ required: true, message: '请输入区域名称', trigger: 'blur' }],
    areaAcreage: [{ validator: twoDecimal }],
    liableUser: [{ required: true, message: '请选择区域负责人', trigger: 'blur' }]
  }

  $refs!: {
    addProjectAreaInfo: ElForm;
  }

  created (): void {
    this.getProjectUserList()
    this.getProjectAreaList()
    this.loadElementList()
    this.loadFacilityList()
  }

  // 获取区域信息
  getProjectAreaList () {
    this.loading = true
    this.$axios.get(this.$apis.project.selectProjectAreaByList, {
      projectId: this.$route.query.projectId
    }).then((res: { list: ProjectArea[]; total: number }) => {
      this.projectAreaList = res.list
      this.isAllAreaSetRange = res.list && res.list[0].isAreaLocation === '1'
      this.loading = false
    })
  }

  // 获取项目人员列表
  getProjectUserList () {
    this.$axios.get(this.$apis.project.selectProjectUserByList, {
      projectId: this.$route.query.projectId
    }).then((res: { list: ProjectUser[]; total: number }) => {
      this.userList = res.list
    })
  }

  // 显示新增区域
  addArea () {
    this.projectAreaTitle = '新增区域'
    this.addProjectAreaInfo = {
      areaName: '',
      areaAcreage: '',
      liableUser: '',
      conserveLevel: '', // 养护等级
      conserveDesc: '', // 养护标准描述
      conserveElementIds: [], // 养护要素IDS
      conservePointIds: [] // 养护点位IDS
    }
    this.showAddProjectArea = true
  }

  // 显示编辑区域
  editArea (row: any) {
    this.projectAreaTitle = '编辑区域'
    this.$axios.get(this.$apis.project.selectProjectAreaInfo, {
      projectAreaId: row.projectAreaId
    }).then(res => {
      res.conserveElementIds = res.conserveElementList.map((item: any) => item.obejctId)
      res.conservePointIds = res.conservePointList.map((item: any) => item.obejctId)
      this.addProjectAreaInfo = res
      this.showAddProjectArea = true
    })
  }

  // 新增区域
  addProjectArea () {
    this.$refs.addProjectAreaInfo.validate(valid => {
      if (valid) {
        const url = this.projectAreaTitle === '新增区域' ? this.$apis.project.insertProjectArea : this.$apis.project.updateProjectArea
        this.$axios.post(url, {
          ...this.addProjectAreaInfo,
          projectId: this.$route.query.projectId
        }).then(res => {
          this.$message.success(this.projectAreaTitle + '成功')
          this.getProjectAreaList()
          this.cancelAddProjectArea()
          // 更新区域信息后需要更新点位绑定信息
          this.loadFacilityList()
        })
      }
    })
  }

  beforeCloseAddProjectArea (done: any) {
    this.cancelAddProjectArea()
    done()
  }

  // 取消添加项目管理人员
  cancelAddProjectArea () {
    this.$refs.addProjectAreaInfo.resetFields()
    this.$refs.addProjectAreaInfo.clearValidate()
    this.showAddProjectArea = false
  }

  // 删除区域
  deleteArea (row: ProjectArea) {
    this.$confirm('确认删除' + row.areaName + '吗?', '删除', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      center: true
    }).then(() => {
      this.$axios.post(this.$apis.project.deleteDataProjectArea, {
        projectAreaId: row.projectAreaId
      }).then(() => {
        this.$message.success('删除成功')
        this.getProjectAreaList()
      })
    })
  }

  // 巡查要素
  loadElementList () {
    this.$axios.get(this.$apis.patrol.selectElementByList).then(res => {
      this.elementList = res.list || []
    })
  }

  loadFacilityList () {
    this.$axios.get(this.$apis.project.selectProjectAreaYhPointList, {
      projectId: this.$route.query.projectId
    }).then(res => {
      this.facilityList = res || []
    })
  }
}
